import request from '../utils/request';

export const page = (query) => {
    return request({
        url: '/sys/unpaid/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};