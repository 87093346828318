<template>
  <div>
    <div style="padding: 20px">
    <div class="handle-box">
              <select-area @areaListId="getAreaListId" :clear="clear"/>
              <el-input v-model="query.studentName" placeholder="学员姓名" style="width: 160px"
                        class="mr10"></el-input>
              <el-input v-model="query.tradeNo" placeholder="支付单号" style="width: 160px"
                        class="mr10"></el-input>
              <el-select  v-model="query.sign" placeholder="支付状态" class="handle-select mr10" clearable>
                <el-option v-for="(item,index) in unpaidState" :key="index"
                           :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
              <el-date-picker style="width: 260px" v-model="createDate" type="daterange" range-separator="至"
                              start-placeholder="创建日期" end-placeholder="创建日期">
              </el-date-picker>
              <el-button type="primary" icon="Search" style="margin-left: 10px"
                         @click="selectUnpaidList()">搜索
              </el-button>
              <el-button type="info" icon="Search" @click="reset">重置</el-button>
              <el-popconfirm @confirm="exportExcel"
                             title="确定要导出吗？"
              >
                <template #reference>
                  <el-button type="primary" icon="Download">导出</el-button>
                </template>
              </el-popconfirm>
            </div>
            <el-table
                height="620px"
                :data="tableData"
                v-loading="tableLoading"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header">
                <el-table-column
                    width="110"
                    show-overflow-tooltip="true"
                    prop="studentRecord.studentName"
                    label="学员姓名"></el-table-column>
                <el-table-column
                    width="123"
                    show-overflow-tooltip="true"
                    prop="studentRecord.studentPhone"
                    label="手机号"></el-table-column>
                <el-table-column
                    width="120"
                    show-overflow-tooltip="true"
                    prop="cardtype.cardname"
                    label="购卡类型">
                  <template #default="scope">
                    <el-tag style="width: 100px;text-align: center" class="sug_info" show-overflow-tooltip="true">
                      {{ scope.row.cardName!= null ? scope.row.cardName: '' }}
                    </el-tag>
                  </template>
                </el-table-column>
              <el-table-column
                  width="120"
                  prop="cardtype.cardname"
                  label="赠卡类型">
                <template #default="scope">
                  <el-popover
                      v-if="scope.row.chatSendOrderList != null"
                      placement="top-start"
                      :width="200"
                      trigger="click"
                  >
                    <div v-for="item in scope.row.chatSendOrderList" :key="item.id">
                      <el-tooltip
                          class="box-item"
                          effect="dark"
                          :content="item.name"
                          placement="top"
                      >
                        <div style="display:flex;">
                          <el-tag style="width: 130px;margin-bottom:10px;text-align: center;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                            {{item.name}}
                          </el-tag>
                          <el-tag style="width: 50px;text-align: center;margin-left: 10px">
                            × {{item.num}}
                          </el-tag>

                        </div>
                      </el-tooltip>

                    </div>
                    <template #reference>
                      <el-tag style="width: 100px;text-align: center" class="sug_info">查看赠卡</el-tag>
                    </template>
                  </el-popover>
                  <el-tag v-else type="info" style="width: 100px;text-align: center" class="sug_info">暂无信息</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                  width="120"
                  prop="cardtype.cardname"
                  label="赠送器材">
                <template #default="scope">
                  <el-popover
                      v-if="scope.row.chatSendEquList != null"
                      placement="top-start"
                      :width="170"
                      trigger="click"
                  >
                    <div v-for="item in scope.row.chatSendEquList" :key="item.id">
                      <el-tooltip
                          class="box-item"
                          effect="dark"
                          :content="item.name"
                          placement="top">
                        <div style="display:flex;">
                          <el-tag style="width: 100px;margin-bottom:10px;text-align: center;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                            {{item.name}}
                          </el-tag>
                          <el-tag style="width: 50px;text-align: center;margin-left: 10px">
                            × {{item.num}}
                          </el-tag>
                        </div>
                      </el-tooltip>

                    </div>
                    <template #reference>
                      <el-tag style="width: 100px;text-align: center" class="sug_info">查看器材</el-tag>
                    </template>
                  </el-popover>
                  <el-tag v-else type="info" style="width: 100px;text-align: center" class="sug_info">暂无信息</el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" label="购买金额" width="90">
                <template #default="scope">
                  {{scope.row.cardMoney/100}}元
                </template>
              </el-table-column>
              <el-table-column
                  width="280"
                  show-overflow-tooltip="true"
                  prop="tradeNo"
                  label="支付单号">
                <template #default="scope">
                  <span v-if="scope.row.tradeNo == null || scope.row.tradeNo == ''" style="color: #969896">暂无支付单号</span>
                  <span v-else>{{scope.row.tradeNo}}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="支付状态" width="180" show-overflow-tooltip>
                <template v-slot="products">
                  <el-tag
                      :type="products.row.signName == '已支付'? 'success': products.row.signName == '待支付'? 'warning':products.row.signName == '支付失败' ? 'danger':'info'">
                    {{ products.row.signName }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                  width="120px"
                  show-overflow-tooltip="true"
                  prop="area.name"
                  label="所属区域"></el-table-column>
                <el-table-column
                    width="160px"
                    show-overflow-tooltip="true"
                    prop="campus.name"
                    label="所属校区"></el-table-column>
                <el-table-column
                    width="165px"
                    show-overflow-tooltip="true"
                    prop="createTime"
                    label="创建时间"></el-table-column>
            </el-table>
          <div class="pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :current-page="query.pageIndex"
                :page-size="query.pageSize"
                :total="pageTotal"
                @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
    </div>
</template>

<script>
import selectArea from "../../components/SelectArea";
import {page} from "@/api/unpaidOrder";
import {selectDictByType} from "@/api/dict";
    export default {
        name: "unpaidOrder",
        components:{
          selectArea
        },
        data() {
            return {
              clear:true,
                query: {
                    areaList:[],
                    studentName:"",
                    tradeNo:"",
                    sign:"",
                    startTime:"",
                    endTime:"",
                    pageIndex: 1,
                    pageSize: 10,
                },
                createDate:null,
                tableData: [],
                pageTotal: 0,
                unpaidState:[],
                tableLoading:false
            }
        },
        created() {
          this.getData();
          selectDictByType('unpaidState').then(res=>{ 
            console.log(res);
          })
          selectDictByType("payState").then(value => {
            this.unpaidState=value.data;
          })
        },
        methods: {
          reset(){
            Object.keys(this.query).forEach(key=>{this.query[key]=''})
            this.query.areaList = []
            this.query.pageIndex = 1
            this.query.pageSize = 10
            this.createDate = null
            this.clear = !this.clear
            this.getData()
          },
          selectUnpaidList(){
            this.query.pageIndex = 1
            this.getData();
          },
          // 获取数据
          getData() {
            this.tableLoading = true;
            if (this.createDate!=null) {
              this.query.startTime = this.dateFormat(this.createDate[0]);
              this.query.endTime = this.dateFormat(this.createDate[1]);
            }else {
              this.query.startTime = "";
              this.query.endTime = "";
            }
            page(this.query).then(res => {
                if (res.code == 200) {
                    this.tableLoading=false;
                    this.tableData = res.data.records;
                    this.pageTotal = res.data.total;
                } else {
                    this.$message.error("获取表格数据失败");
                }
            })
          },
          // 分页导航
          handlePageChange(val) {
            this.query.pageIndex = val
            this.getData();
          },
          getAreaListId(val){
            this.query.areaList = val;
          },
          dateFormat(val) {
            var date = new Date(val)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            return time
          },
        }
    }

</script>

<style scoped>
/deep/.el-table  .cell { 
  overflow:inherit;
}
    .code {
        margin-top: 6px;
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: #efefef;
    }

    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>